import { Coin } from '../../shared/coin/Coin';
import './index.scss';


type RewardsInput = {
    totalDeposit: number;
    lastMonthTotalDeposit: number;
}
export const LeaderboardRewards = (input: RewardsInput) => {
    const totalDeposit = input.totalDeposit;
    const prizesPercentageCut = {
        "1": 10,
        "2": 5,
        "3": 3,
        "4-10": 1,
    }
    const prizes = Object.entries(prizesPercentageCut).map(([position, percentage]) => {
        const emoji = position === "1" ? "👑" : "🎲";
        const commision = totalDeposit / 10;
        const prizeHard = commision * percentage / 100;
        const prize = prizeHard.toFixed(0);
        return <div className='prize'>
            {/* <div className='decoration'>
                <img src="./assets/2x.png" alt='2x'/>
            </div> */}
            <p className='position'>Prize #{position} {emoji} </p>
            <span className='amount'>{parseInt(prize).toFixed(2)}<Coin/></span>
            <p className='lastMonthAmount'>~{(input.lastMonthTotalDeposit/10*percentage/100).toFixed(2)} last month</p>
        </div>
    })
    return <div className="rewards">
        <div className='total-rewards'>
            <span>{parseInt((totalDeposit/10/100*25).toFixed(0)).toFixed(2)} <Coin/> </span>
            <img src="./assets/total-rewards.png" alt='Total prize' className='background-image'/>
        </div>
        <div className='title'>
            <h2>Rewards increase with each deposit 🔥</h2>
        </div>
        <div className='prizes'>
            {prizes}
        </div>
        <div className='explanation'>
            <h4>How to participate?</h4>
            <ol>
                <li>Deposit using code <span className='code'>BUDI</span> / <span className='code'>BUDIYT</span> / <span className='code'>BUDICS</span></li>
                <li>Each coin deposited grants you a ticket🎫 and increases the pot</li>
                <li>Random winners will be picked live on stream</li>
                <li>If you win, balance will be added to your CSGORoll account</li>
            </ol>
        </div>
    </div>
}