import { Link } from "react-router-dom";
import { LeaderboardBanner } from "./banner/Leaderboard-Banner";
import './index.scss';
import { LeaderboardResults } from "./results/Leaderboard-Results";
import { LeaderboardRewards } from "./rewards/Leaderboard-Rewards";
import { useEffect, useState } from "react";




export const Leaderboard = () => {
    const [total, setTotal] = useState<number>(0);
    useEffect(() => {
        import(`./results/data/january.json`).then((module) => {
            const data = module.default;
            const total = data.filter(n => !n.statuses.includes('STOLEN')).reduce((acc: number, curr: Record<string, any>) => {
                return acc + parseFloat(curr.amount);
            }, 0);
            setTotal(total);
        })
    }, []);
    return         <div className="leaderboard">
            <LeaderboardBanner />
            <LeaderboardRewards  totalDeposit={total} lastMonthTotalDeposit={380290*2-58680}/>
            <Link to="/leaderboard_december">See previous leaderboard results</Link>
            <LeaderboardResults  dataSource="january" title="January" timestamp="26.01.2025. at 10:50 CET"/>
        </div>
}